<template>
  <div class="financialStatement">
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="formInline.stall_number" placeholder="输入商铺号">
            <template slot="pre">商铺号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.fee_name" clearable placeholder="请选择费用类型">
            <el-option v-for="(item, index) in feeNamesList" :key="index" :label="item.contract_fee_name"
              :value="item.contract_fee_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="date" :clearable="false" @change="dateChange" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <MyButton title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="90529" @click="exported">
            <span>导出</span>
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="public-table">
        <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center', background: 'rgb(245, 245, 245)' }"
          :cell-style="{ 'text-align': 'center' }" v-loading="loading">
          <el-table-column label="商铺号" prop="stall_number"></el-table-column>
          <el-table-column label="费用类型" prop="fee_name"></el-table-column>
          <el-table-column label="期初余额" prop="opening_balance"></el-table-column>
          <el-table-column label="本期发生额">
            <el-table-column label="应收金额" prop="receivable_amount"></el-table-column>
            <el-table-column label="减免金额" prop="deduction_amount"></el-table-column>
            <el-table-column label="已收金额" prop="received_amount"></el-table-column>
          </el-table-column>
          <el-table-column label="未收金额" prop="outstanding_amount">
            <template slot-scope="scope">
              {{ Number(parseFloat(Number(scope.row.opening_balance) + Number(scope.row.receivable_amount) -
                Number(scope.row.deduction_amount) -
                Number(scope.row.received_amount)).toFixed(2)) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="public-page">
        <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
          :page-size="formInline.pageSize" :current-page="formInline.pageNum"
          @current-change="currentChange"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'financialStatement',
  data () {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        beginTime: '',
        endTime: '',
        fee_name: '',
        stall_number: ''
      },
      date: [],
      tableData: [],
      loading: false,
      total: 0,
      feeNamesList: []
    }
  },
  created () {
    let date = new Date();
    let val = this.$common.formatDateTime(date);
    this.date = [val, val];
    this.formInline.beginTime = val;
    this.formInline.endTime = val;
    this.getType();
    this.getList();
  },
  methods: {
    getType () {
      this.$request.HttpPost('/financial/feeNames').then((res) => {
        this.feeNamesList = res.data || [];
      })
    },
    getList () {
      this.$request.HttpPost('/financial/stall', this.formInline).then((res) => {
        this.tableData = res.data.list || [];
        this.total = res.data.total;
      })
    },
    dateChange (date) {
      if (date) {
        this.formInline.beginTime = date[0];
        this.formInline.endTime = date[1];
      } else {
        this.formInline.beginTime = '';
        this.formInline.endTime = '';
      }
    },
    currentChange (page = 1) {
      this.formInline.pageNum = page;
      this.getList();
    },
    exported () {
      this.$request.HttpPost("/financial/stallExport", this.formInline, {
        responseType: "blob",
      }).then((res) => {
        let blob = new Blob([res]);
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = "商铺财务报表.xlsx";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      });
    }
  }
}
</script>
<style lang="scss" scoped></style>
